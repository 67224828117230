import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

import linkIcon from '../images/product_link.png'
import talkIcon from '../images/product_talk.png'
import textIcon from '../images/product_text.png'
import chatIcon from '../images/product_chat.png'

const StyledFooter = styled.footer`
    width: 100%;
    font-size: 0.75rem;
    color: #8368DB;
    bottom: 1rem;
    text-align: center;
    padding: 6rem 0 1rem;
    position: absolute;
    bottom: 0;

    > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;

    img {
        width: 50px;
        margin: 0.25rem;
    }
}
`

const StyledLink = styled(Link)`
    color: #8368DB;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const Footer = () => (
    <StyledFooter>
        <div>
            <img src={chatIcon} alt="SeeBotChat" />
            <img src={talkIcon} alt="SeeBotTalk" />
            <img src={textIcon} alt="SeeBotText" />
            <img src={linkIcon} alt="SeeBotLink" />
        </div>
        <p><StyledLink to="https://seebot.run">©2020 SeeBotRun LLC</StyledLink></p>
        <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
    </StyledFooter>
)

export default Footer