import React from 'react'
import logo from '../images/sbr-logo.svg'
import { Link } from 'gatsby'

const Logo = () => (
    <Link to="/">
        <img style={{ maxWidth:'175px', margin: 'auto', padding: '2rem 0', display: 'block'}} src={logo} alt="SeeBotRun" />
    </Link>
)

export default Logo